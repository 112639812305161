// import { DesktopOutlined } from "@ant-design/icons";

import { baseRoutes } from "../../../helpers/baseRoutes";

const accessRoute = {
  TOTAL_PHONE_SOLD: {
    path: `${baseRoutes.superAdminBasePath}/total-phone-sold`,
  },
};

export default accessRoute;
