import { TotalEarnMoney } from "../../../pages";
import channelPartnerRouteMap from "../../../routeControl/channelPartnerRouteMap";

export default function route() {
  return [
    {
      path: channelPartnerRouteMap.TOTAL_EARN_MONEY.path,
      name: "Sales Analytics",
      key: channelPartnerRouteMap.TOTAL_EARN_MONEY.path,
      private: true,
      belongsToSidebar: false,
      // icon: channelPartnerRouteMap.TOTAL_EARN_MONEY.icon,
      element: <TotalEarnMoney />,
    },
  ];
}
