import { UserEkycDetails } from "../../../pages";
import subAdminRouteMap from "../../../routeControl/subAdminRouteMap";

export default function route() {
  return [
    {
      path: `${subAdminRouteMap.USEREKYCDETAILS.path}/:id`,
      name: "User Ekyc Details",
      key: `${subAdminRouteMap.USEREKYCDETAILS.path}/:id`,
      private: true,
      belongsToSidebar: false,
      icon: subAdminRouteMap.USEREKYCDETAILS.icon,
      element: <UserEkycDetails />,
    },
  ];
}
