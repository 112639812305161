import { Notification } from "../../../pages";
import channelPartnerRouteMap from "../../../routeControl/channelPartnerRouteMap";

export default function route() {
  return [
    {
      path: channelPartnerRouteMap.NOTIFICATION.path,
      name: "Notification",
      key: channelPartnerRouteMap.NOTIFICATION.path,
      private: true,
      belongsToSidebar: false,
      icon: channelPartnerRouteMap.NOTIFICATION.icon,
      element: <Notification />,
    },
  ];
}
