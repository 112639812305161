// import { DesktopOutlined } from "@ant-design/icons";

import { baseRoutes } from "../../../helpers/baseRoutes";

const accessRoute = {
  CHENNAL_PROFILE: {
    path: `${baseRoutes.adminBaseRoutes}/profile`,
  },
  CHENNAL_CHANGE_PASSWORD: {
    path: `${baseRoutes.adminBaseRoutes}/change-password`,
  },
};

export default accessRoute;
