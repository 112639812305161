// import { DesktopOutlined } from "@ant-design/icons";

import { baseRoutes } from "../../../helpers/baseRoutes";

const accessRoute = {
    DOCUMENTS: {
    path: `${baseRoutes.superAdminBasePath}/documents`,
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-file-check"/>
      </span>
    ),
  },
};

export default accessRoute;
