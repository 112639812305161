// import { DesktopOutlined } from "@ant-design/icons";

import { baseRoutes } from "../../../helpers/baseRoutes";

const accessRoute = {
  SUB_ADMIN_PROFILE: {
    path: `${baseRoutes.superAdminBasePath}/sub-admin-profile`,
  },
  SUB_ADMIN_PASSWORD: {
    path: `${baseRoutes.superAdminBasePath}/sub-admin-change-password`,
  },
};

export default accessRoute;
