// import { DesktopOutlined } from "@ant-design/icons";

import { baseRoutes } from "../../../helpers/baseRoutes";

const accessRoute = {
  SALES_ANALYTICS: {
    path: `${baseRoutes.adminBaseRoutes}/sales-analytics`,
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-activity-round" />
      </span>
    ),
  },
};

export default accessRoute;
