import auth from "./Auth";
import dashboard from "./Dashboard";
import chennalProfile from "./Account";
import paymentMethod from "./PaymentMethod";
import settings from "./Settings";
import selesAnalytics from "./SalesAnalytics";
import customer from "./Customer";
import totalEarnMoney from "./TotalEarnMoney";
import notification from "./Notification";
import referrals from "./Referrals";
import transactionHistory from "./TransactionHistory";

const AccessControl = {
  ...auth,
  ...dashboard,
  ...chennalProfile,
  ...paymentMethod,
  ...settings,
  ...selesAnalytics,
  ...customer,
  ...totalEarnMoney,
  ...notification,
  ...referrals,
  ...transactionHistory,
};

export default AccessControl;
