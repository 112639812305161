// import { DesktopOutlined } from "@ant-design/icons";

import { baseRoutes } from "../../../helpers/baseRoutes";

const accessRoute = {
  SUPER_ADMIN_PROFILE: {
    path: `${baseRoutes.superAdminBasePath}/profile`,
  },
  SUPER_ADMIN_PASSWORD: {
    path: `${baseRoutes.superAdminBasePath}/change-password`,
  },
};

export default accessRoute;
