import publicRoutes from "./public.route";
import privateRoutes from "./private.route";
import { SubAdminPrivateLayout, SubAdminPublicLayout } from "../../layouts";

export const SubAdminRoutes = () => {
  return [
    {
      element: <SubAdminPublicLayout />,
      children: [...publicRoutes()],
    },
    {
      element: <SubAdminPrivateLayout />,
      children: [...privateRoutes()],
    },
  ];
};
