/* eslint-disable camelcase */
import DashboardRoutes from "./Dashboard/index.route";
import ekyc from "./Ekyc/index.route";
import profileUpdate from "./ProfileUpdate/index.route";
import inbox from "./Inbox/index.route";
import telephony from "./Telephony/index.route";
import AccountRoutes from "./Account/index.route";
import recordings from "./Recordings/index.route";
import billing from "./Billing/index.route";
import CallHistory from "./CallHistory/index.route";
import PostBox from "./PostBox/index.route";
import buyNumber from "./BuyNumber/index.route";
// import survey from "./Survey/index.route";
import cartItems from "./Cart/index.route";
import notification from "./Notification/index.route";
import tnc from "./Tnc/inde.route";

export default function route() {
  let customerType = localStorage.getItem("customer_type");
  // let buyStatus = localStorage.getItem("buyStatus");
  // if (!buyStatus) {
  //   return [...buyNumber()];
  // } else
  if (customerType !== "Individual") {
    return [
      ...AccountRoutes(),
      ...DashboardRoutes(),
      ...telephony(),
      ...recordings(),
      ...billing(),
      ...CallHistory(),
      ...inbox(),
      ...PostBox(),
      ...ekyc(),
      ...buyNumber(),
      // ...survey(),
      ...profileUpdate(),
      ...cartItems(),
      ...notification(),
      ...tnc(),
    ];
  } else {
    return [
      ...AccountRoutes(),
      ...DashboardRoutes(),
      ...telephony(),
      ...recordings(),
      ...billing(),
      ...CallHistory(),
      ...inbox(),
      ...PostBox(),
      ...ekyc(),
      ...buyNumber(),
      // ...survey(),
      ...cartItems(),
      ...notification(),
      ...tnc(),
    ];
  }
}
