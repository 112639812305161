import { PostBox } from "../../../pages";
import userRouteMap from "../../../routeControl/userRouteMap";

export default function route() {
  return [
    {
      path: userRouteMap.POST_BOX.path,
      name: "Mail (Post) Box",
      key: userRouteMap.POST_BOX.path,
      private: true,
      belongsToSidebar: true,
      icon: userRouteMap.POST_BOX.icon,
      element: <PostBox />,
    },
  ];
}
