import { TransactionHistory } from "../../../pages";
import channelPartnerRouteMap from "../../../routeControl/channelPartnerRouteMap";

export default function route() {
  return [
    {
      path: channelPartnerRouteMap.TRANSACTION_HISTORY.path,
      name: "Payment History",
      key: channelPartnerRouteMap.TRANSACTION_HISTORY.path,
      private: true,
      belongsToSidebar: true,
      icon: channelPartnerRouteMap.TRANSACTION_HISTORY.icon,
      element: <TransactionHistory />,
    },
  ];
}
