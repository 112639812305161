import { SalesAnalytics } from "../../../pages";
import channelPartnerRouteMap from "../../../routeControl/channelPartnerRouteMap";

export default function route() {
  return [
    {
      path: channelPartnerRouteMap.SALES_ANALYTICS.path,
      name: "Sales Analytics",
      key: channelPartnerRouteMap.SALES_ANALYTICS.path,
      private: true,
      belongsToSidebar: true,
      icon: channelPartnerRouteMap.SALES_ANALYTICS.icon,
      element: <SalesAnalytics />,
    },
  ];
}
