// import { DesktopOutlined } from "@ant-design/icons";

import { baseRoutes } from "../../../helpers/baseRoutes";

const accessRoute = {
  PARTNERSDETAILS: {
    path: `${baseRoutes.superAdminBasePath}/Associates-details`,
  },
};

export default accessRoute;
