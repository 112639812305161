import { CartItems } from "../../../pages";
import userRouteMap from "../../../routeControl/userRouteMap";

export default function route() {
  return [
    {
      path: userRouteMap.CARTITEMS.path,
      name: "Cart Number",
      key: userRouteMap.CARTITEMS.path,
      private: true,
      belongsToSidebar: false,
      icon: userRouteMap.CARTITEMS.icon,
      element: <CartItems />,
    },
  ];
}
