import { UserEkyc } from "../../../pages";
import superAdminRouteMap from "../../../routeControl/superAdminRouteMap";

export default function route() {
  return [
    {
      path: superAdminRouteMap.USEREKYC.path,
      name: "User KYC",
      key: superAdminRouteMap.USEREKYC.path,
      private: true,
      belongsToSidebar: true,
      icon: superAdminRouteMap.USEREKYC.icon,
      element: <UserEkyc />,
    },
  ];
}
