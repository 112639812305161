import { Notification } from "../../../pages";
import userRouteMap from "../../../routeControl/User";

export default function route() {
  return [
    {
      path: userRouteMap.NOTIFICATION.path,
      name: "Notification",
      key: userRouteMap.NOTIFICATION.path,
      private: true,
      belongsToSidebar: false,
      icon: userRouteMap.NOTIFICATION.icon,
      element: <Notification />,
    },
  ];
}
