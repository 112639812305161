import { SuperAdminChangePassword, SuperAdminProfile } from "../../../pages";
import subAdminRouteMap from "../../../routeControl/subAdminRouteMap";

export default function route() {
  return [
    {
      path: subAdminRouteMap.SUB_ADMIN_PROFILE.path,
      name: "Profile",
      key: subAdminRouteMap.SUB_ADMIN_PROFILE.path,
      private: true,
      belongsToSidebar: false,
      element: <SuperAdminProfile />,
    },
    {
      path: subAdminRouteMap.SUB_ADMIN_PASSWORD.path,
      name: "Change Password",
      key: subAdminRouteMap.SUB_ADMIN_PASSWORD.path,
      private: true,
      belongsToSidebar: false,
      element: <SuperAdminChangePassword />,
    },
  ];
}
