import { baseRoutes } from "../../../helpers/baseRoutes";

const accessRoute = {
  LOGIN: {
    path: `${baseRoutes.superAdminBasePath}`,
  },
  CUSTOMER_SIGNUP: {
    path: `${baseRoutes.superAdminBasePath}/customer-signup`,
  },
  CHANNELSIGNUP: {
    path: `${baseRoutes.superAdminBasePath}/channel-signup`,
  },
  FORGOT_PASSWORD: {
    path: `${baseRoutes.superAdminBasePath}/forgot-password`,
  },
  RESET_PASSWORD: {
    path: `${baseRoutes.superAdminBasePath}/reset-password`,
  },
  CHANGE_PASSWORD: {
    path: `${baseRoutes.superAdminBasePath}/change-password`,
  },
};

export default accessRoute;
