// import { DesktopOutlined } from "@ant-design/icons";

import { baseRoutes } from "../../../helpers/baseRoutes";

const accessRoute = {
  PARTNER_KYC: {
    path: `${baseRoutes.superAdminBasePath}/partner/kyc`,
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-coin" />
      </span>
    ),
  },
};

export default accessRoute;
