// import { DesktopOutlined } from "@ant-design/icons";

import { baseRoutes } from "../../../helpers/baseRoutes";

const accessRoute = {
  USEREKYCDETAILS: {
    path: `${baseRoutes.superAdminBasePath}/user-ekyc-details`,
  },
};

export default accessRoute;
