// import { DesktopOutlined } from "@ant-design/icons";

import { baseRoutes } from "../../../helpers/baseRoutes";

const accessRoute = {
  TOTAL_EARN_MONEY: {
    path: `${baseRoutes.superAdminBasePath}/total-earn-money`,
  },
};

export default accessRoute;
