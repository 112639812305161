/* eslint-disable no-underscore-dangle */
import CryptoJS from "crypto-js";
import moment from "moment";
import { Link } from "react-router-dom";
import config from "../config";
import userRouteMap from "../routeControl/userRouteMap";

const getItem = (path, label, key, icon, children, withAuth) => {
  if (children) {
    return { label, key, icon, children, path, withAuth };
  }
  return { label, key, icon, path, withAuth };
};

export const getSideBarData = (arr) => {
  if (arr instanceof Array) {
    return arr.reduce((prev, item) => {
      if (item?.belongsToSidebar) {
        if (item.children instanceof Array) {
          const children = item.children.reduce(
            (prevElement, currentSubChild) => {
              if (currentSubChild?.belongsToSidebar) {
                prevElement.push(
                  getItem(
                    currentSubChild?.path,
                    currentSubChild?.name,
                    currentSubChild?.key,
                    currentSubChild?.icon,
                    ""
                  )
                );
              }
              return prevElement;
            },
            []
          );
          prev.push(
            getItem(item?.path, item?.name, item?.key, item?.icon, children)
          );
        } else {
          prev.push(getItem(item?.path, item?.name, item?.key, item?.icon));
        }
      }
      return prev;
    }, []);
  }
  return [];
};
export function ImageElement({
  previewSource = "",
  source,
  alt = "image",
  ...rest
}) {
  let pattern = /^\//g;
  return (
    <>
      {previewSource ? (
        <img src={previewSource} alt={alt} {...rest} />
      ) : (
        <img
          src={`${config.IMAGE_URL}/${source?.replace(pattern, "")}`}
          alt={alt}
          {...rest}
        />
      )}
    </>
  );
}

export const setLocalStorageToken = (token) => {
  localStorage.setItem(
    `${config.NAME_KEY}:token`,
    CryptoJS.AES.encrypt(token, `${config.NAME_KEY}-token`).toString()
  );
};

export const getLocalStorageToken = () => {
  const token = localStorage.getItem(`${config.NAME_KEY}:token`);
  if (token) {
    const bytes = CryptoJS.AES.decrypt(token, `${config.NAME_KEY}-token`);
    return bytes.toString(CryptoJS.enc.Utf8);
  }
  return false;
};

export function dateFormatter(params, format) {
  if (params === null) {
    return "-";
  }
  return moment(params)?._i?.format(format ?? config.DATE_FORMAT);
}

export const firstLetterCaps = (text) => {
  try {
    if (text) {
      const originalString = text.toLowerCase().split("");
      originalString[0] = originalString[0].toUpperCase();
      return originalString.join("");
    } else {
      return text;
    }
  } catch (error) {
    console.log(error);
  }
};
export const getFullName = (first, middle, last) => {
  try {
    let name = firstLetterCaps(first);
    if (!name) {
      return "";
    }
    if (middle) {
      name += ` ${firstLetterCaps(middle)}`;
    }
    if (last) {
      name += ` ${firstLetterCaps(last)}`;
    }
    return name;
  } catch (error) {
    console.log(error);
  }
};
export const checkValidData = (data) => {
  return data || "-";
};
export const checkValidCount = (data) => {
  return <>{data || 0}</>;
};
export const removeLocalStorageToken = (userData, navigate) => {
  if (localStorage.getItem(`${config.NAME_KEY}:token`)) {
    localStorage.setItem(`${config.NAME_KEY}:token`, null);
  }
  navigate(
    userData === "channelPartner"
      ? "associate"
      : userData === "admin" || userData === "subadmin"
        ? "superAdmin"
        : userRouteMap.LOGIN.path
  );
};
export function decodeQueryData(data) {
  return JSON.parse(
    `{"${decodeURI(data)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"')
      .replace("?", "")}"}`
  );
}
export const navigateWithParam = (data, navigate, pathname) => {
  const searchParams = new URLSearchParams(data).toString();
  navigate(`${pathname}?${searchParams}`);
};

export function enterOnlyNumericValue(e) {
  const ASCIICode = e.which ? e.which : e.keyCode;
  const keyCode = e.charCode;
  if (
    (ASCIICode > 31 && ASCIICode > 57) ||
    keyCode === 43 ||
    keyCode === 42 ||
    keyCode === 45 ||
    keyCode === 47 ||
    keyCode === 33 ||
    keyCode === 35 ||
    keyCode === 36 ||
    keyCode === 37 ||
    keyCode === 38 ||
    keyCode === 44 ||
    keyCode === 40 ||
    keyCode === 41 ||
    keyCode === 39 ||
    keyCode === 34 ||
    keyCode === 32
  ) {
    e.preventDefault();
  }
}
export function readMoreTextShow(data, showMoreText) {
  if ([undefined, null, false].includes(data)) {
    return <></>;
  }
  if (data.props) {
    if (data.props.children.length < 50) {
      return <>{firstLetterCaps(data)}</>;
    }
  } else if (data.length < 50) {
    return <>{firstLetterCaps(data)}</>;
  }
  return (
    <p className="mb-0">
      {data.props
        ? data.props.children.type === "br"
          ? " - "
          : data.props.children.substring(0, 50)
        : data.substring(0, 50)}

      {data.props ? (data.props.children.type === "br" ? " " : "... ") : "..."}

      {showMoreText ? (
        <Link
          to="/"
          onClick={(e) => {
            e.preventDefault();
            showMoreText({ data });
          }}
          className="link-secondary"
        >
          Read More
          {/* {t("common.readMore")} */}
        </Link>
      ) : (
        ""
      )}
    </p>
  );
}
export const serialNumberFormatter = (page, sizePerPage, index) => {
  return (page - 1) * sizePerPage + index + 1;
};

export function otpRegex() {
  let regex = /^[0-9]+$/;
  return regex;
}

export const getNumberFormatter = (countryCode, number) => {
  let result = number?.slice(countryCode?.length);
  return `+${countryCode || "-"} ${result || "-"}`;
};
