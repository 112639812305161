// import { DesktopOutlined } from "@ant-design/icons";

import { baseRoutes } from "../../../helpers/baseRoutes";

const accessRoute = {
  TRANSACTION_HISTORY: {
    path: `${baseRoutes.adminBaseRoutes}/transaction-history`,
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-history"/>
      </span>
    ),
  },
};

export default accessRoute;
