import { DesktopOutlined } from "@ant-design/icons";
import {
  CommonResetPassword,
  ForgotPasswordChannel,
  SuperAdminLogin,
} from "../../pages";
import superAdminRouteMap from "../../routeControl/superAdminRouteMap";

export default function route() {
  return [
    {
      path: superAdminRouteMap.LOGIN.path,
      name: "Login",
      key: superAdminRouteMap.LOGIN.path,
      private: false,
      belongsToSidebar: false,
      icon: <DesktopOutlined />,
      element: <SuperAdminLogin />,
    },
    {
      path: superAdminRouteMap.FORGOT_PASSWORD.path,
      name: "Forgot Password",
      key: superAdminRouteMap.FORGOT_PASSWORD.path,
      private: false,
      belongsToSidebar: false,
      icon: <DesktopOutlined />,
      element: <ForgotPasswordChannel />,
    },
    {
      path: superAdminRouteMap.RESET_PASSWORD.path,
      name: "Forgot Password",
      key: superAdminRouteMap.RESET_PASSWORD.path,
      private: false,
      belongsToSidebar: false,
      icon: <DesktopOutlined />,
      element: <CommonResetPassword />,
    },
  ];
}
