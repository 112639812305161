import { PartnersDetails } from "../../../pages";
import superAdminRouteMap from "../../../routeControl/superAdminRouteMap";

export default function route() {
  return [
    {
      path: `${superAdminRouteMap.PARTNERSDETAILS.path}/:id`,
      name: "Associates Details",
      key: `${superAdminRouteMap.PARTNERSDETAILS.path}/:id`,
      private: true,
      belongsToSidebar: false,
      icon: superAdminRouteMap.PARTNERSDETAILS.icon,
      element: <PartnersDetails />,
    },
  ];
}
