// import { DesktopOutlined } from "@ant-design/icons";

import { baseRoutes } from "../../../helpers/baseRoutes";

const accessRoute = {
  SETTINGS: {
    path: baseRoutes.adminBaseRoutes,
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-setting" />
      </span>
    ),
  },
  PROFILE_PARTNER: {
    path: `${baseRoutes.adminBaseRoutes}/profile`,
  },
  EKYCPARTNER: {
    path: `${baseRoutes.adminBaseRoutes}/kyc`,
  },
  CHANGEPASSWORD: {
    path: `${baseRoutes.adminBaseRoutes}/changepassword`,
  },
};

export default accessRoute;
