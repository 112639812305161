import React from "react";

export { default as AppLayout } from "./App/index.layout";
// export {default as MainLayout} from "./Main/index.layout";
// export {default as PublicLayout} from "./Public/index.layout";

// export const AppLayout = Lazy(()=>import("./Auth/index.layout"));
export const MainLayout = React.lazy(() => import("./Main/index.layout"));
export const PublicLayout = React.lazy(() => import("./Public/index.layout"));
export const PrivateLayout = React.lazy(() => import("./Private/index.layout"));
export const AuthLayout = React.lazy(() => import("./Auth/index.layout"));
export const CustomerLayout = React.lazy(() =>
  import("./Customer/index.layout")
);
export const CustomerPublicLayout = React.lazy(() =>
  import("./Customer/public.layout")
);
export const CustomerPrivateLayout = React.lazy(() =>
  import("./Customer/private.layout")
);
export const UserPublicLayout = React.lazy(() =>
  import("./User/public.layout")
);
export const UserPrivateLayout = React.lazy(() =>
  import("./User/private.layout")
);
export const UserLayout = React.lazy(() => import("./User/index.layout"));
export const ChannelLayout = React.lazy(() =>
  import("./channelPartner/index.layout")
);
export const ChannelPublicLayout = React.lazy(() =>
  import("./channelPartner/public.layout")
);
export const ChannelPrivateLayout = React.lazy(() =>
  import("./channelPartner/private.layout")
);
export const SuperAdminLayout = React.lazy(() =>
  import("./SuperAdmin/index.layout")
);
export const SuperAdminPublicLayout = React.lazy(() =>
  import("./SuperAdmin/public.layout")
);
export const SuperAdminPrivateLayout = React.lazy(() =>
  import("./SuperAdmin/private.layout")
);
export const SubAdminLayout = React.lazy(() =>
  import("./SubAdmin/index.layout")
);
export const SubAdminPublicLayout = React.lazy(() =>
  import("./SubAdmin/public.layout")
);
export const SubAdminPrivateLayout = React.lazy(() =>
  import("./SubAdmin/private.layout")
);
