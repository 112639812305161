import auth from "./Auth";
import dashboard from "./Dashboard";
import superAdminProfile from "./Account";
import totalEarnMoney from "./TotalEarnMoney";
import totalPhoneSold from "./TotalPhoneSold";
import totalUsers from "./TotalUsers";
import totalPartners from "./TotalPartners";
import salesAnalytics from "./SalesAnalytics";
import users from "./Users";
import userEkyc from "./UserEkyc";
import partners from "./Partners";
import partnersDetails from "./PartnersDetails";
import userEkycDetails from "./UserEkycDetails";
import partnerKyc from "./PartnerKyc/index";
import partnerkycDetails from "./PartnerKycDetails/index";
import billing from "./Billing/index";
import refferals from "./Refferals/index";
import documents from "./Documents/index";
import channelPartnerCommision from "./ChannelPartnerCommision/index";
import addCommision from "./AddCommision/index";
import broadcast from "./Broadcast/index";

const AccessControl = {
  ...auth,
  ...dashboard,
  ...superAdminProfile,
  ...totalEarnMoney,
  ...totalPhoneSold,
  ...totalUsers,
  ...totalPartners,
  ...salesAnalytics,
  ...users,
  ...userEkyc,
  ...userEkycDetails,
  ...partners,
  ...partnersDetails,
  ...userEkyc,
  ...partnerKyc,
  ...partnerkycDetails,
  ...billing,
  ...refferals,
  ...documents,
  ...addCommision,
  ...channelPartnerCommision,
  ...broadcast,
};

export default AccessControl;
