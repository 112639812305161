import { TotalUsers } from "../../../pages";
import superAdminRouteMap from "../../../routeControl/superAdminRouteMap";

export default function route() {
  return [
    {
      path: superAdminRouteMap.USERSUPER.path,
      name: "Users",
      key: superAdminRouteMap.USERSUPER.path,
      private: true,
      belongsToSidebar: true,
      icon: superAdminRouteMap.USERSUPER.icon,
      element: <TotalUsers />,
    },
  ];
}
