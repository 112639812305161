import { BroadcastNotification } from "../../../pages";
import superAdminRouteMap from "../../../routeControl/superAdminRouteMap";

export default function route() {
  return [
    {
      path: superAdminRouteMap.BROADCAST.path,
      name: "Broadcast",
      key: superAdminRouteMap.BROADCAST.path,
      private: true,
      belongsToSidebar: true,
      icon: superAdminRouteMap.BROADCAST.icon,
      element: <BroadcastNotification />,
    },
  ];
}
