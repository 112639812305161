// import { DesktopOutlined } from "@ant-design/icons";

import { baseRoutes } from "../../../helpers/baseRoutes";

const accessRoute = {
  NOTIFICATION: {
    path: `${baseRoutes.adminBaseRoutes}/notification`,
  },
};

export default accessRoute;
