// import { DesktopOutlined } from "@ant-design/icons";

import { baseRoutes } from "../../../helpers/baseRoutes";

const accessRoute = {
  BILLING: {
    path: baseRoutes.superAdminBasePath,
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-report-profit" />
      </span>
    ),
  },
  PREPARED_ACCOUNT: {
    path: `${baseRoutes.superAdminBasePath}/prepared-account`,
  },
  PLAN: {
    path: `${baseRoutes.superAdminBasePath}/plan`,
  },
  PAYMENT_METHOD: {
    path: `${baseRoutes.superAdminBasePath}/payment-method`,
  },
  RECIEPT: {
    path: `${baseRoutes.superAdminBasePath}/reciept`,
  },
  USAGE: {
    path: `${baseRoutes.superAdminBasePath}/usage`,
  },
};

export default accessRoute;
