import { TotalUsers, UserDetails } from "../../../pages";
import superAdminRouteMap from "../../../routeControl/superAdminRouteMap";

export default function route() {
  return [
    {
      path: superAdminRouteMap.TOTAL_USERS.path,
      name: "Total users",
      key: superAdminRouteMap.TOTAL_USERS.path,
      private: true,
      belongsToSidebar: false,
      //   icon: superAdminRouteMap.DASHBOARD.icon,
      element: <TotalUsers />,
    },
    {
      path: `${superAdminRouteMap.USERS_DETIALS.path}/:id`,
      name: "User Details",
      key: `${superAdminRouteMap.USERS_DETIALS.path}/:id`,
      private: true,
      belongsToSidebar: false,
      //   icon: superAdminRouteMap.DASHBOARD.icon,
      element: <UserDetails />,
    },
  ];
}
