import { UserEkycDetails } from "../../../pages";
import superAdminRouteMap from "../../../routeControl/superAdminRouteMap";

export default function route() {
  return [
    {
      path: `${superAdminRouteMap.USEREKYCDETAILS.path}/:id`,
      name: "User Ekyc Details",
      key: `${superAdminRouteMap.USEREKYCDETAILS.path}/:id`,
      private: true,
      belongsToSidebar: false,
      icon: superAdminRouteMap.USEREKYCDETAILS.icon,
      element: <UserEkycDetails />,
    },
  ];
}
