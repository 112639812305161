import { SuperAdminDashboard } from "../../../pages";
import superAdminRouteMap from "../../../routeControl/superAdminRouteMap";

export default function route() {
  return [
    {
      path: superAdminRouteMap.DASHBOARD.path,
      name: "Dashboard",
      key: superAdminRouteMap.DASHBOARD.path,
      private: true,
      belongsToSidebar: true,
      icon: superAdminRouteMap.DASHBOARD.icon,
      element: <SuperAdminDashboard />,
    },
  ];
}
