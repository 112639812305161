import { PartnerKycDetails } from "../../../pages";
import subAdminRouteMap from "../../../routeControl/subAdminRouteMap";

export default function route() {
  return [
    {
      path: `${subAdminRouteMap.PARTNER_KYC_DETAILS.path}/:id`,
      name: "Associates kyc Details",
      key: `${subAdminRouteMap.PARTNER_KYC_DETAILS.path}/:id`,
      private: true,
      belongsToSidebar: false,
      icon: subAdminRouteMap.PARTNER_KYC_DETAILS.icon,
      element: <PartnerKycDetails />,
    },
  ];
}
