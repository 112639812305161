import { DesktopOutlined } from "@ant-design/icons";
import {
  ChannelSignup,
  ChannelLogin,
  ForgotPasswordChannel,
  CommonResetPassword,
  UserVerification,
} from "../../pages";
import channelPartnerRouteMap from "../../routeControl/channelPartnerRouteMap";

export default function route() {
  return [
    {
      path: channelPartnerRouteMap.LOGIN.path,
      name: "Login",
      key: channelPartnerRouteMap.LOGIN.path,
      private: false,
      belongsToSidebar: false,
      icon: <DesktopOutlined />,
      element: <ChannelLogin />,
    },
    {
      path: channelPartnerRouteMap.CHANNELSIGNUP.path,
      name: "Channel Signup",
      key: channelPartnerRouteMap.CHANNELSIGNUP.path,
      private: false,
      belongsToSidebar: false,
      icon: <DesktopOutlined />,
      element: <ChannelSignup />,
    },
    {
      path: channelPartnerRouteMap.FORGOT_PASSWORD.path,
      name: "Forgot Password",
      key: channelPartnerRouteMap.FORGOT_PASSWORD.path,
      private: false,
      belongsToSidebar: false,
      icon: <DesktopOutlined />,
      element: <ForgotPasswordChannel />,
    },
    {
      path: channelPartnerRouteMap.RESET_PASSWORD.path,
      name: "Forgot Password",
      key: channelPartnerRouteMap.RESET_PASSWORD.path,
      private: false,
      belongsToSidebar: false,
      icon: <DesktopOutlined />,
      element: <CommonResetPassword />,
    },
    {
      path: channelPartnerRouteMap.USER_VERIFY.path,
      name: "User Verify",
      key: channelPartnerRouteMap.USER_VERIFY.path,
      private: false,
      belongsToSidebar: false,
      icon: <DesktopOutlined />,
      element: <UserVerification />,
    },
  ];
}
