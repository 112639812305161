import { DesktopOutlined } from "@ant-design/icons";
import {
  ResetPassword,
  UserLogin,
  UserSignUp,
  ForgotPassword,
  UserVerification,
} from "../../pages";
import userRouteMap from "../../routeControl/userRouteMap";

export default function route() {
  return [
    {
      path: userRouteMap.LOGIN.path,
      name: "Login",
      key: userRouteMap.LOGIN.path,
      private: false,
      belongsToSidebar: false,
      icon: <DesktopOutlined />,
      element: <UserLogin />,
    },
    {
      path: userRouteMap.CUSTOMER_SIGNUP.path,
      name: "User SignUp",
      key: userRouteMap.CUSTOMER_SIGNUP.path,
      private: false,
      belongsToSidebar: false,
      icon: <DesktopOutlined />,
      element: <UserSignUp />,
    },
    {
      path: userRouteMap.RESET_PASSWORD.path,
      name: "Reset Password",
      key: userRouteMap.RESET_PASSWORD.path,
      private: false,
      belongsToSidebar: false,
      icon: <DesktopOutlined />,
      element: <ResetPassword />,
    },
    {
      path: userRouteMap.FORGOT_PASSWORD.path,
      name: "Forgot Password",
      key: userRouteMap.FORGOT_PASSWORD.path,
      private: false,
      belongsToSidebar: false,
      icon: <DesktopOutlined />,
      element: <ForgotPassword />,
    },
    {
      path: userRouteMap.USER_VERIFY.path,
      name: "User Verify",
      key: userRouteMap.USER_VERIFY.path,
      private: false,
      belongsToSidebar: false,
      icon: <DesktopOutlined />,
      element: <UserVerification />,
    },
  {
        path: userRouteMap.USER_VERIFY.path,
        name: "tnc",
        key: userRouteMap.USER_VERIFY.path,
        private: false,
        belongsToSidebar: false,
        icon: <DesktopOutlined />,
        element: <UserVerification />,
      }
  ];
}
