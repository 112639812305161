import { Conversation } from "../../../pages";
import userRouteMap from "../../../routeControl/userRouteMap";

export default function route() {
  return [
    {
      path: userRouteMap.CONVERSATION.path,
      name: "Text (SMS) Inbox",
      key: userRouteMap.CONVERSATION.path,
      private: true,
      belongsToSidebar: true,
      icon: userRouteMap.CONVERSATION.icon,
      element: <Conversation />,
    },
  ];
}
