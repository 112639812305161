// import { DesktopOutlined } from "@ant-design/icons";

import { baseRoutes } from "../../../helpers/baseRoutes";

const accessRoute = {
  TNC: {
    path: `${baseRoutes.userBaseRoutes}tnc`,
    icon: (
        <span className="nk-menu-icon">
          {/* <em className="icon ni ni-user-alt" /> */}
          <em className="icon ni ni-bar-chart-alt"/>
        </span>
      )
  },
};

export default accessRoute;
