// import { DesktopOutlined } from "@ant-design/icons";

import { baseRoutes } from "../../../helpers/baseRoutes";

const accessRoute = {
  TOTAL_USERS: {
    path: `${baseRoutes.superAdminBasePath}/total-users`,
  },
  USERS_DETIALS: {
    path: `${baseRoutes.superAdminBasePath}/users-details`,
  },
};

export default accessRoute;
