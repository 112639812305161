import { AddCommision } from "../../../pages";
import superAdminRouteMap from "../../../routeControl/superAdminRouteMap";

export default function route() {
  return [
    {
      path: superAdminRouteMap.ADDCOMMISION.path,
      name: "Set Price",
      key: superAdminRouteMap.ADDCOMMISION.path,
      private: true,
      belongsToSidebar: true,
      icon: superAdminRouteMap.ADDCOMMISION.icon,
      element: <AddCommision />,
    },
  ];
}
