import React from "react";

export const ChannelDashboard = React.lazy(() =>
  import("./Dashboard/index.page")
);
export const ChannelSignup = React.lazy(() =>
  import("./ChannelSignup/index.page")
);
export const ChannelLogin = React.lazy(() => import("./Login/index.page"));
export const ForgotPasswordChannel = React.lazy(() =>
  import("./ForgotPassword/index.page")
);
export const PaymentMethod = React.lazy(() =>
  import("./PaymentMethod/index.page")
);
export const ChannelProfile = React.lazy(() =>
  import("./Account/Profile/index.page")
);
// export * from "./Settings";
export const ChangeProfilePassword = React.lazy(() =>
  import("./Account/ChangePassword/index.page")
);
export const Customer = React.lazy(() => import("./Customer/index.page"));
export const SalesAnalytics = React.lazy(() =>
  import("./SalesAnalytics/index.page")
);
export const TotalEarnMoney = React.lazy(() =>
  import("./TotalEarnMoney/index.page")
);

export const Profile = React.lazy(() => import("./Account/Profile/index.page"));
export const EkycPartner = React.lazy(() =>
  import("./Settings/Ekyc/index.page")
);
export const ChannelReferrals = React.lazy(() =>
  import("./Referrals/index.page")
);

export const TransactionHistory = React.lazy(() =>
  import("./TransactionHistory/index.page")
);
// export const ChangePassword = React.lazy(() =>
//   import("./Account/ChangePassword/index.page")
// );
export const CommonResetPassword = React.lazy(() =>
  import("./ResetPassword/index.page")
);

export const TermsAndConditions = React.lazy(() =>
  import("./PaymentMethod/termsAndConditions")
);
