import { BuyNumber } from "../../../pages";
import userRouteMap from "../../../routeControl/userRouteMap";

export default function route() {
  return [
    {
      path: userRouteMap.BUY_NUMBER.path,
      name: "Add Number",
      key: userRouteMap.BUY_NUMBER.path,
      private: true,
      belongsToSidebar: true,
      icon: userRouteMap.BUY_NUMBER.icon,
      element: <BuyNumber />,
    },
  ];
}
