import { DesktopOutlined } from "@ant-design/icons";
import { SuperAdminLogin } from "../../pages";
import superAdminRouteMap from "../../routeControl/superAdminRouteMap";

export default function route() {
  return [
    {
      path: superAdminRouteMap.LOGIN.path,
      name: "Login",
      key: superAdminRouteMap.LOGIN.path,
      private: false,
      belongsToSidebar: false,
      icon: <DesktopOutlined />,
      element: <SuperAdminLogin />,
    },
    // {
    //   path: superAdminRouteMap.CHANNELSIGNUP.path,
    //   name: "Channel Signup",
    //   key: superAdminRouteMap.CHANNELSIGNUP.path,
    //   private: false,
    //   belongsToSidebar: false,
    //   icon: <DesktopOutlined />,
    //   element: <ChannelSignup />,
    // },
  ];
}
