/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */
import { createSlice } from "@reduxjs/toolkit";
import { useSelector, useDispatch } from "react-redux";

let initialState = {
  sidebarKey: 0,
  loginInfo: {},
  isLoggedin: false,
  loginAdminInfo: {},
  isAdminLoggedin: false,
  cartDetails: [],
  sonetelTokenPayload: {},
  profilePic: "",
  allCount: {},
  allNotification: {},

};
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateSidebarKey: (state, action) => {
      state.sidebarKey = action.payload;
    },
    updateLoginInfo: (state, action) => {
      state.loginInfo = action.payload;
      state.isLoggedin = true;
    },
    updateProfilePic: (state, action) => {
      state.profilePic = action.payload;
    },
    removeLoginInfo(state) {
      state.loginInfo = "";
      state.isLoggedin = false;
    },
    updateAddToCard: (state, action) => {
      state.cartDetails = action.payload;
    },
    updateToken: (state, action) => {
      state.sonetelTokenPayload = action.payload;
    },
    totalCount: (state, action) => {
      console.log("action", action.payload);
      state.allCount = action.payload;
    },
    updateNotification: (state, action) => {
        state.allNotification = action.payload;
      },
  },
});

export function useAuthStore() {
  const loginInfo = useSelector((state) => state.auth.loginInfo);
  const sidebarKey = useSelector((state) => state.auth.sidebarKey);
  const isLoggedin = useSelector((state) => state.auth.isLoggedin);
  const loginAdminInfo = useSelector((state) => state.auth.loginAdminInfo);
  const cartDetails = useSelector((state) => state.auth.cartDetails);
  const profilePic = useSelector((state) => state.auth.profilePic);
  const allNotification = useSelector((state) => state.auth.allNotification);
  const sonetelTokenPayload = useSelector(
    (state) => state.auth.sonetelTokenPayload
  );
  const allCount = useSelector((state) => state.auth.allCount);
  const dispatch = useDispatch();
  return {
    sidebarKey,
    loginInfo,
    profilePic,
    isLoggedin,
    loginAdminInfo,
    cartDetails,
    allCount,
    allNotification,
    sonetelTokenPayload,
    updateSidebarKey: (payload) => dispatch(updateSidebarKey(payload)),
    updateLoginInfo: (payload) => dispatch(updateLoginInfo(payload)),
    updateProfilePic: (payload) => dispatch(updateProfilePic(payload)),
    removeLoginInfo: () => dispatch(removeLoginInfo()),
    updateAddToCard: (payload) => dispatch(updateAddToCard(payload)),
    updateToken: (payload) => dispatch(updateToken(payload)),
    totalCount: (payload) => dispatch(totalCount(payload)),
    updateNotification: (payload) => dispatch(updateNotification(payload)),
  };
}

export const {
  updateSidebarKey,
  updateLoginInfo,
  updateProfilePic,
  removeLoginInfo,
  updateAddToCard,
  updateNotification,
  updateToken,
  totalCount,
} = authSlice.actions;

export default authSlice.reducer;
