import { TotalPartners } from "../../../pages";
import superAdminRouteMap from "../../../routeControl/superAdminRouteMap";

export default function route() {
  return [
    {
      path: superAdminRouteMap.PARTNERS.path,
      name: "Associates",
      key: superAdminRouteMap.PARTNERS.path,
      private: true,
      belongsToSidebar: true,
      icon: superAdminRouteMap.PARTNERS.icon,
      element: <TotalPartners />,
    },
  ];
}
