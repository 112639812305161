import { PartnersKyc } from "../../../pages";
import subAdminRouteMap from "../../../routeControl/subAdminRouteMap";

export default function route() {
  return [
    {
      path: subAdminRouteMap.PARTNER_KYC.path,
      name: "Associates KYC",
      key: subAdminRouteMap.PARTNER_KYC.path,
      private: true,
      belongsToSidebar: true,
      icon: subAdminRouteMap.PARTNER_KYC.icon,
      element: <PartnersKyc />,
    },
  ];
}
