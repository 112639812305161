// import { DesktopOutlined } from "@ant-design/icons";

import { baseRoutes } from "../../../helpers/baseRoutes";

const accessRoute = {
  TOTAL_PARTNERS: {
    path: `${baseRoutes.superAdminBasePath}/total-Associates`,
  },
};

export default accessRoute;
