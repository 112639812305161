// import { useSelector } from "react-redux";
import { useLocation, generatePath, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { AuthDriver as authDriver } from "../../utils";
import { getCompletePathList } from "../../route";
// import superAdminRouteMap from "../../routeControl/superAdminRouteMap";
import { useAuthStore } from "../../redux";
// import superAdminRouteMap from "../../routeControl/superAdminRouteMap";

function AppLayout({ setRedirectPath, children }) {
  const location = useLocation();
  const params = useParams();
  const { loginInfo } = useAuthStore();
  const userData = loginInfo?.user_type ? loginInfo : {};

  function getGeneratedPath(data) {
    try {
      return generatePath(data, params);
    } catch (error) {
      return data;
    }
  }

  const activeRoute = getCompletePathList().find((e) => {
    const routePath = getGeneratedPath(e.key);
    const browserPath = location.pathname;
    return routePath === browserPath;
  });

  const isPrivate = activeRoute?.private;
  const isValid = authDriver(activeRoute, userData, location.pathname);
  function checkValid() {
    if (!isValid) {
      let publicPath = "";
      let privatePath = "";
      let path = "";

      if (location.pathname.search("superAdmin") >= 0) {
        path = "admin";
      }
      if (location.pathname.search("associate") >= 0) {
        path = "channel patner";
      } else if (location.pathname === "/") {
        path = "customer";
      } else if (location.pathname.search("superAdmin") >= 0) {
        path = "subadmin";
      }

      let checkData = userData?.user_type ?? path;
      if (checkData === "admin") {
        publicPath = "/superAdmin";
        privatePath = "/superAdmin/dashboard";
      } else if (checkData === "subadmin") {
        publicPath = "/superAdmin";
        privatePath = "/superAdmin/documents";
      } else if (checkData === "channel patner") {
        publicPath = "/associate";
        privatePath = "/associate/dashboard";
      } else if (checkData === "customer") {
        publicPath = "/";
        privatePath = "/dashboard";
      } else {
        publicPath = "/";
      }

      if (isPrivate === true) {
        /** ******* If route type is private but not*****************
         * ******* able to prove their identity as valid entity*****
         * ********* so redirect it to public route******** */
        // window.location = publicPath;
        // modalNotification({
        //   type: "warning",
        //   message: "Your Token is expired. Please login again.",
        // });
        setRedirectPath(publicPath);

        // return false;
      } else if (isPrivate === false) {
        /** ******* If route type is public but not*****************
         * ******* able to prove their identity as valid entity*****
         * ********* so redirect it to private route******** */
        // window.location = privatePath;
        setRedirectPath(privatePath);
      }
    } else {
      setRedirectPath(location?.pathname);
    }
  }

  useEffect(() => {
    checkValid();
  }, [location.pathname]);

  return <>{isValid && children}</>;
}

export default AppLayout;
