import superAdminProfile from "./Account";
import documents from "./Documents/index";
import userEkyc from "./UserEkyc";
import userEkycDetails from "./UserEkycDetails";
import partnerKyc from "./PartnerKyc/index";
import partnerkycDetails from "./PartnerKycDetails/index";

const AccessControl = {
  ...superAdminProfile,
  ...documents,
  ...userEkyc,
  ...userEkycDetails,
  ...partnerKyc,
  ...partnerkycDetails,
};

export default AccessControl;
