import { SuperAdminChangePassword, SuperAdminProfile } from "../../../pages";
import superAdminRouteMap from "../../../routeControl/superAdminRouteMap";

export default function route() {
  return [
    {
      path: superAdminRouteMap.SUPER_ADMIN_PROFILE.path,
      name: "Profile",
      key: superAdminRouteMap.SUPER_ADMIN_PROFILE.path,
      private: true,
      belongsToSidebar: false,
      element: <SuperAdminProfile />,
    },
    {
      path: superAdminRouteMap.SUPER_ADMIN_PASSWORD.path,
      name: "Change Password",
      key: superAdminRouteMap.SUPER_ADMIN_PASSWORD.path,
      private: true,
      belongsToSidebar: false,
      element: <SuperAdminChangePassword />,
    },
  ];
}
