import { PartnerKycDetails } from "../../../pages";
import superAdminRouteMap from "../../../routeControl/superAdminRouteMap";

export default function route() {
  return [
    {
      path: `${superAdminRouteMap.PARTNER_KYC_DETAILS.path}/:id`,
      name: "Associates kyc Details",
      key: `${superAdminRouteMap.PARTNER_KYC_DETAILS.path}/:id`,
      private: true,
      belongsToSidebar: false,
      icon: superAdminRouteMap.PARTNER_KYC_DETAILS.icon,
      element: <PartnerKycDetails />,
    },
  ];
}
