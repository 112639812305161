// import { DesktopOutlined } from "@ant-design/icons";

import { baseRoutes } from "../../../helpers/baseRoutes";

const accessRoute = {
  SALESANALYTICSADMIN: {
    path: `${baseRoutes.superAdminBasePath}/sales-analytics`,
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-activity-round-fill"/>
      </span>
    ),
  },
//   USERSEARCH: {
//     path: `${baseRoutes.superAdminBasePath}user-search`
//   },
};

export default accessRoute;
