// import { DesktopOutlined } from "@ant-design/icons";

import { baseRoutes } from "../../../helpers/baseRoutes";

const accessRoute = {
  PAYMENTMETHOD: {
    path: `${baseRoutes.adminBaseRoutes}/earn-money`,
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-coins" />
      </span>
    ),
  },
  TERMSANDCONDTIONS: {
    path: `${baseRoutes.adminBaseRoutes}/terms-and-condions`,
   
  },
};

export default accessRoute;
