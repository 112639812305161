import { EkycPage } from "../../../pages";
import userRouteMap from "../../../routeControl/userRouteMap";

export default function route() {
  return [
    {
      path: userRouteMap.EKYC.path,
      name: "E-KYC",
      key: userRouteMap.EKYC.path,
      private: true,
      belongsToSidebar: true,
      icon: userRouteMap.EKYC.icon,
      element: <EkycPage />,
    },
  ];
}
