/** ****Function is driver function for authenticate*******
 * ****user and route for which it will**************
 * ****return true and false************************** */

import { getLocalStorageToken } from "./common.util";

function authDriver(route, userData, pathname) {
  try {
    let checkData = getLocalStorageToken();
    let user = "";

    // if (userData?.user_type === "admin" && pathname.search("superAdmin") >= 0) {
    //   user = "admin";
    // } else if (
    //   userData?.user_type === "channel patner" &&
    //   pathname.search("associate") >= 0
    // ) {
    //   user = "channel patner";
    // } else if (
    //   userData?.user_type === "customer" &&
    //   pathname.search("/") >= 0
    // ) {
    //   user = "customer";
    // } else if (
    //   userData?.user_type === "subadmin" &&
    //   pathname.search("superAdmin") >= 0
    // ) {
    //   user = "subadmin";
    // } else if (pathname.search("superAdmin") >= 0) {
    //   user = "admin";
    // } else if (pathname.search("associate") >= 0) {
    //   user = "channel patner";
    // } else if (pathname.search("/") >= 0) {
    //   user = "customer";
    // }

    if (
      userData?.user_type === "subadmin" &&
      pathname.search("superAdmin") >= 0
    ) {
      user = "subadmin";
    } else if (pathname.search("superAdmin") >= 0) {
      user = "admin";
    } else if (pathname.search("associate") >= 0) {
      user = "channel patner";
    } else {
      user = "customer";
    }

    if (route.private && userData?.user_type === user) {
      // ********For secure route**************
      if (!!checkData) {
        return true;
      } else {
        return false;
      }
    } else {
      // **Non secure route****
      if (!!checkData) {
        return false;
      }
      if (route.private === false) {
        return true;
      } else {
        return false;
      }
    }
  } catch (err) {
    return false;
  }
}

export default authDriver;
