import { ChannelPrivateLayout, ChannelPublicLayout } from "../../layouts";
import publicRoutes from "./public.route";
import privateRoutes from "./private.route";

export const chennalRoutes = (t) => {
  return [
    {
      element: <ChannelPublicLayout />,
      children: [...publicRoutes(t)]
    },
    {
      element: <ChannelPrivateLayout />,
      children: [...privateRoutes(t)]
    }
  ];
};
