import { Refferals } from "../../../pages";
import superAdminRouteMap from "../../../routeControl/superAdminRouteMap";

export default function route() {
  return [
    {
      path: superAdminRouteMap.REFERRALS.path,
      name: "Referrals",
      key: superAdminRouteMap.REFERRALS.path,
      private: true,
      belongsToSidebar: true,
      icon: superAdminRouteMap.REFERRALS.icon,
      element: <Refferals />,
    },
  ];
}
