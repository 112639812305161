import { ChangeProfilePassword, ChannelProfile } from "../../../pages";
import channelPartnerRouteMap from "../../../routeControl/channelPartnerRouteMap";

export default function route() {
  return [
    {
      path: channelPartnerRouteMap.CHENNAL_PROFILE.path,
      name: "Profile",
      key: channelPartnerRouteMap.CHENNAL_PROFILE.path,
      private: true,
      belongsToSidebar: false,
      element: <ChannelProfile />,
    },
    {
      path: channelPartnerRouteMap.CHENNAL_CHANGE_PASSWORD.path,
      name: "Change Password",
      key: channelPartnerRouteMap.CHENNAL_CHANGE_PASSWORD.path,
      private: true,
      belongsToSidebar: false,
      element: <ChangeProfilePassword />,
    },
  ];
}
