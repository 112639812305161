import { ChannelReferrals } from "../../../pages";
import channelPartnerRouteMap from "../../../routeControl/channelPartnerRouteMap";

export default function route() {
  return [
    {
      path: channelPartnerRouteMap.REFERRALS.path,
      name: "Referral",
      key: channelPartnerRouteMap.REFERRALS.path,
      private: true,
      belongsToSidebar: true,
      icon: channelPartnerRouteMap.REFERRALS.icon,
      element: <ChannelReferrals />,
    },
  ];
}
