import { PaymentMethod, TermsAndConditions } from "../../../pages";
import channelPartnerRouteMap from "../../../routeControl/channelPartnerRouteMap";

export default function route() {
  return [
    {
      path: channelPartnerRouteMap.PAYMENTMETHOD.path,
      name: "Withdraw Money",
      key: channelPartnerRouteMap.PAYMENTMETHOD.path,
      private: true,
      belongsToSidebar: true,
      icon: channelPartnerRouteMap.PAYMENTMETHOD.icon,
      element: <PaymentMethod />,
    },
    {
      path: channelPartnerRouteMap.TERMSANDCONDTIONS.path,
      name: "Terms And Conditions",
      key: channelPartnerRouteMap.TERMSANDCONDTIONS.path,
      private: true,
      belongsToSidebar: false,
      // icon: channelPartnerRouteMap.TERMSANDCONDTIONS.icon,
      element: <TermsAndConditions />,
    },
  ];
}
