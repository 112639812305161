import React from "react";

export const SuperAdminDashboard = React.lazy(() =>
  import("./Dashboard/index.page")
);

export const SuperAdminLogin = React.lazy(() => import("./Login/index.page"));

export const SuperAdminProfile = React.lazy(() =>
  import("./Account/Profile/index.page")
);

export const TotalEarnMoneyAdmin = React.lazy(() =>
  import("./TotalEarnMoney/index.page")
);
export const TotalPhoneSold = React.lazy(() =>
  import("./TotalPhoneSold/index.page")
);
export const TotalUsers = React.lazy(() => import("./TotalUsers/index.page"));
export const TotalPartners = React.lazy(() =>
  import("./TotalPartners/index.page")
);
export const UserSuper = React.lazy(() => import("./Users/index.page"));
export const UserEkyc = React.lazy(() => import("./UserEkyc/index.page"));
export const UserEkycDetails = React.lazy(() =>
  import("./UserEkycDetails/index.page")
);
export const PartnersDetails = React.lazy(() =>
  import("./PartnersDetails/index.page")
);
// export const SalesAnalyticsSuper = React.lazy(() => import("./SalesAnalytics/UserSearch/index.page"));
export * from "./SalesAnalytics";

export const SuperAdminChangePassword = React.lazy(() =>
  import("./Account/ChangePassword/index.page")
);
export const PartnersKyc = React.lazy(() => import("./Partnerkyc/index.page"));
export const Refferals = React.lazy(() => import("./Refferals/index.page"));
export const DocumentsPage = React.lazy(() => import("./Documents/index.page"));
export const AddCommision = React.lazy(() =>
  import("./AddCommision/index.page")
);
export const ChannelPartnerCommision = React.lazy(() =>
  import("./ChannelPartnerCommision/index.page")
);
export const PartnerKycDetails = React.lazy(() =>
  import("./PartnerKycDetailsPage/index.page")
);
export * from "./AdminBilling";
export const UserDetails = React.lazy(() =>
  import("./TotalUsers/details.page")
);
export const BroadcastNotification = React.lazy(() =>
  import("./Broadcast/index.page")
);
