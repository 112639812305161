// import { DesktopOutlined } from "@ant-design/icons";

import { baseRoutes } from "../../../helpers/baseRoutes";

const accessRoute = {
  PARTNER_KYC_DETAILS: {
    path: `${baseRoutes.superAdminBasePath}/partner-ekyc-details`,
  },
};

export default accessRoute;
