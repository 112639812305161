/* eslint-disable import/no-extraneous-dependencies */
import "./App.css";
import { Suspense } from "react";
import { useRoutes } from "react-router-dom";
import { Spin } from "antd";
import { routes } from "./route";

function RouteLayout({ path }) {
  const element = useRoutes(path);
  return element;
}

function App() {
  return (
    <>
      <Suspense
        fallback=<div className="d-flex vh-100  align-items-center justify-content-center mt-5">
          <Spin spinning />
        </div>
      >
        <RouteLayout path={routes()} />
      </Suspense>
    </>
  );
}

export default App;
