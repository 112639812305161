import { Customer } from "../../../pages";
import channelPartnerRouteMap from "../../../routeControl/channelPartnerRouteMap";

export default function route() {
  return [
    {
      path: channelPartnerRouteMap.CUSTOMER.path,
      name: "Customer",
      key: channelPartnerRouteMap.CUSTOMER.path,
      private: true,
      belongsToSidebar: true,
      icon: channelPartnerRouteMap.CUSTOMER.icon,
      element: <Customer />,
    },
  ];
}
