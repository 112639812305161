import { Tnc } from "../../../pages";
import userRouteMap from "../../../routeControl/User";

export default function route() {
  return [
    {
      path: userRouteMap.TNC.path,
      name: "tnc",
      key: userRouteMap.TNC.path,
      private: true,
      belongsToSidebar: false,
      icon: userRouteMap.TNC.icon,
      element: <Tnc />,
    },
  ];
}
