// import { DesktopOutlined } from "@ant-design/icons";

import { baseRoutes } from "../../../helpers/baseRoutes";

const accessRoute = {
  REFERRALS: {
    path: `${baseRoutes.adminBaseRoutes}/referrals`,
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-offer" />
      </span>
    ),
  },
};

export default accessRoute;
