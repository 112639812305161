import { DocumentsPage } from "../../../pages";
import subAdminRouteMap from "../../../routeControl/subAdminRouteMap";

export default function route() {
  return [
    {
      path: subAdminRouteMap.DOCUMENTS.path,
      name: "Documents",
      key: subAdminRouteMap.DOCUMENTS.path,
      private: true,
      belongsToSidebar: true,
      icon: subAdminRouteMap.DOCUMENTS.icon,
      element: <DocumentsPage />,
    },
  ];
}
